import axios from 'axios';
import Vue from 'vue';
import Cookie from 'js-cookie';
import router from '../router';

axios.defaults.withCredentials = true;

// 封装一个axios实例
const http = axios.create({
	// 通用请求的地址前缀
	//baseURL: 'https://api.yooumi.com/admin',
	//baseURL: process.env.VUE_APP_SERVER + '/admin',
	baseURL: 'https://party.api.juandouyun.com/admin',
	// 超时时间
	timeout: 100000,
});

// 请求拦截器
http.interceptors.request.use(
	function (config) {
		// 在发送请求之前做什么
		return config;
	},
	function (error) {
		// 对请求错误做什么
		return Promise.reject(error);
	}
);

// 添加响应拦截器
http.interceptors.response.use(
	function (response) {
		// 对响应数据做什么
		return response;
	},
	function (error) {
		// 对响应错误做什么
		return Promise.reject(error);
	}
);

const request = {
	data: null,
	errCode: null,

	post: function (url, params, success, fail) {
		var contentType = 'application/json';
		if (contentType.substring(1, 7) === 'upload') {
			contentType = 'multipart/form-data';
		}
		var promise;
		if (params) {
			promise = http.post(url, params, {
				headers: {
					'Content-Type': contentType,
					'Access-Control-Max-Age': '3600',
					Token: Cookie.get('token'),
				},
			});
		} else {
			promise = http.post(url, null, {
				headers: {
					'Content-Type': contentType,
					'Access-Control-Max-Age': '3600',
					Token: Cookie.get('token'),
				},
			});
		}
		promise.then((response) => {
			if (response.data.code == 200) {
				if (success) {
					success(response.data.data);
				}
			} else if (response.data.code == 401) {
				Cookie.remove('token');
				Cookie.remove('menu');
				router.push('/login');
			} else {
				if (fail) {
					fail(response.errorCode, response.data.data);
				} else {
					if (response.data.message) {
						Vue.prototype.$message.error(response.data.message);
					}
				}
			}
		});
	},

	success: function (data) {
		return data;
	},

	fail: function (errCode, data) {
		return {
			errCode: errCode,
			data: data,
		};
	},
};

export default request;
